<template lang="pug">
  v-dialog(
    width="500"
    :value="show"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h2.modal-title {{ 'pages.auth.change_password' | translate }}
        v-card-head-toolbar
          v-btn(
            icon
            gray
            @click="onClose") x
      v-card-content
        .form-group
          label {{ 'pages.auth.old_password' | translate }}
          input(
            type="password"
            autocomplete="new-password"
            class="form-control"
            :placeholder="'ui.inputs.enter_old_password' | translate "
            v-model="oldPassword")
          span.form-text.invalid {{ 'oldPassword' | errorText }}
        .form-group
          label {{ 'pages.auth.new_password' | translate }}
          input(
            type="password"
            autocomplete="new-password"
            class="form-control"
            :placeholder="'ui.inputs.enter_new_password' | translate "
            v-model="newPassword")
          span.form-text.invalid {{ 'newPassword' | errorText }}
        .form-group
          label {{ 'ui.inputs.repeat_new_password' | translate }}
          input(
            type="password"
            autocomplete="new-password"
            class="form-control"
            :placeholder="'ui.inputs.repeat_new_password' | translate "
            v-model="repeatPassword")
          span.form-text.invalid {{ 'repeatPassword' | errorText }}
      v-card-actions
        v-btn(
          :loading="loading"
          @click="savePassword") {{ 'pages.auth.set_new_password' | translate}}
</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'

export default {
  name: 'ChangePasswordModal',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.clearData()
  },

  destroyed () {
    this.clearData()
  },

  data: () => ({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
    loading: false
  }),

  methods: {
    ...mapActions('app', [
      'clearErrors'
    ]),

    async savePassword () {
      this.clearErrors()
      this.loading = true
      const body = {}
      body.oldPassword = this.oldPassword
      body.newPassword = this.newPassword
      body.repeatPassword = this.repeatPassword
      const { error } = await api.employeeChangePassword(body)
      if (!error) {
        this.onClose()
      }
      this.loading = false
    },

    onClose () {
      this.clearData()
      this.$emit('on-close')
    },

    clearData () {
      this.clearErrors()
      this.oldPassword = ''
      this.newPassword = ''
      this.repeatPassword = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
