<template lang="pug">
  .settings-view
    .kt-permissions.row.pb-4
      .st-container
        .row.settings
          .col-md-3
            tabs-list
          .col-md-9
            keep-alive
              v-card.settings-card
                component(@apply-changes="applyChanges" :loading="loading" :is="isComponent" :userInfoForm="userInfoForm"
                  :isOwnProfileEditingPermitted="isOwnProfileEditingPermitted" @showAlert="setShowAlert")
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

import TabsList from './common/TabsList'
import ProfileGeneral from './form/General'
import ProfileDetails from './form/Details'
import ProfileSettings from './form/Settings'
import EmailPreference from './form/EmailPreference'

export default {
  name: 'Profile',

  components: {
    TabsList,
    'general': ProfileGeneral,
    'organization': ProfileDetails,
    'settings': ProfileSettings,
    'email-preference': EmailPreference
  },

  async mounted () {
    if (this.userInfo.id !== undefined) {
      this.init()
    }
    this.clearErrors()
  },

  destroyed () {
    this.clearErrors()
    this.setShowAlert(false)
  },

  data: () => ({
    loading: false,
    userInfoForm: {
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      phoneNumber: '',
      department: {},
      location: {},
      position: {},
      language: {}
    }
  }),

  methods: {
    ...mapActions('app', [
      'updateUserInfo',
      'clearErrors',
      'setPopupMessage'
    ]),

    init () {
      for (const field in this.userInfoForm) {
        this.userInfoForm[field] = this.userInfo[field]
      }
    },

    setShowAlert (param) {
      if (param) {
        this.setPopupMessage({
          message: this.$t('pages.profile.changes_successfully_saved')
        })
      } else {
        this.setPopupMessage(null)
      }
    },

    async applyChanges (form) {
      this.loading = true
      this.clearErrors()
      let data = {}
      if (!this.isOwnProfileEditingPermitted) {
        data = {
          description: form.description,
          phoneNumber: form.phoneNumber,
          languageId: form.language.id
        }
      } else {
        data = {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          description: form.description,
          phoneNumber: form.phoneNumber,
          departmentId: form.department.id,
          locationId: form.location.id,
          positionId: form.position.id,
          languageId: form.language.id
        }
      }
      const successfullUpdate = await this.updateUserInfo(data)
      this.loading = false
      if (successfullUpdate) {
        this.setShowAlert(true)
      } else {
        this.setShowAlert(false)
      }
    }

  },

  computed: {
    ...mapGetters('app', [
      'userInfo',
      'isOwnProfileEditingPermitted'
    ]),

    isComponent () {
      return this.$route.params.id
    }
  },

  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler () {
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  padding: 32px 24px 24px 24px !important;

  &-card {
    padding-top: 14px;
    .v-card__content {
      padding: 18px 24px 40px !important;
    }
  }
}

.profile {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .kt-portlet__form-buttons {
    button {
      width: 100%;
    }
  }
}
</style>
