<template lang="pug">
  v-scrollable(:bottom-offset="20")
    v-card-content
      .row
        .col-md-6
          .form-group
            label {{ 'ui.labels.department' | translate }}
            v-select(
              single
              :disabled="!currentPermissions.PAGE_ADMINISTRATION || !isOwnProfileEditingPermitted"
              :tabindex="1"
              :placeholder="'ui.dropdown.select_department' | translate "
              :options="departments"
              v-model="userInfoForm.department")
            span.form-text.invalid {{ 'departmentId' | errorText }}

          .form-group
            label {{ 'ui.labels.location' | translate }}
            v-select(
              single
              :disabled="!currentPermissions.PAGE_ADMINISTRATION || !isOwnProfileEditingPermitted"
              :tabindex="3"
              :placeholder="'ui.dropdown.select_location' | translate "
              :options="locations"
              v-model="userInfoForm.location")
            span.form-text.invalid {{ 'locationId' | errorText }}

        .col-md-6
          .form-group
            label {{ 'ui.labels.position' | translate }}
            v-select(
              single
              :disabled="!currentPermissions.PAGE_ADMINISTRATION || !isOwnProfileEditingPermitted"
              :tabindex="2"
              :placeholder="'ui.dropdown.select_position' | translate "
              :options="positions"
              v-model="userInfoForm.position")
            span.form-text.invalid {{ 'positionId' | errorText }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProfileDetails',

  props: {
    userInfoForm: Object,
    isOwnProfileEditingPermitted: Boolean
  },

  mounted () {
    if (this.currentPermissions.PAGE_ADMINISTRATION && this.isOwnProfileEditingPermitted) {
      this.loadDepartments()
      this.loadLocations()
      this.loadPositions()
    }
  },

  methods: {
    ...mapActions('positions', [
      'loadPositions'
    ]),

    ...mapActions('locations', [
      'loadLocations'
    ]),

    ...mapActions('departments', [
      'loadDepartments'
    ])
  },

  computed: {
    ...mapGetters('positions', [
      'positions'
    ]),

    ...mapGetters('departments', [
      'departments'
    ]),

    ...mapGetters('locations', [
      'locations'
    ]),

    ...mapGetters('permissions', [
      'currentPermissions'
    ])
  }
}
</script>

<style lang="scss" scoped>
.form-group > label {
  color: $body-text;
  font-weight: 400;
}
</style>
