<template lang="pug">
  v-scrollable(:bottom-offset="20")
    v-card-content
      .row
        .col-md-6
          .form-group
            label {{ 'ui.labels.first_name' | translate }}
            input.text-input(
              :readonly="!isOwnProfileEditingPermitted"
              tabindex="1"
              class="form-control"
              :placeholder="'ui.inputs.enter_first_name' | translate "
              v-model="userInfoForm.firstName")

            span.form-text.invalid {{ 'firstName' | errorText }}

          .form-group
            label {{ 'ui.labels.e_mail' | translate }}
            input.text-input(
              :readonly="!isOwnProfileEditingPermitted"
              tabindex="3"
              class="form-control"
              :placeholder="'ui.inputs.enter_e_mail' | translate "
              v-model="userInfoForm.email")

            span.form-text.invalid {{ 'email' | errorText }}

          .form-group
            label {{ 'ui.labels.phone_number' | translate }}
            input.text-input(
              class="form-control"
              tabindex="5"
              :placeholder="'ui.inputs.enter_phone_number' | translate "
              v-model="userInfoForm.phoneNumber")
            span.form-text.invalid {{ 'phoneNumber' | errorText }}

        .col-md-6
          .form-group
            label {{ 'ui.labels.last_name' | translate }}
            input.text-input(
              :readonly="!isOwnProfileEditingPermitted"
              tabindex="2"
              class="form-control"
              :placeholder="'ui.inputs.enter_last_name' | translate "
              v-model="userInfoForm.lastName")
            span.form-text.invalid {{ 'lastName' | errorText }}

          .form-group
            label {{ 'ui.labels.description' | translate }}
            textarea(
              tabindex="4"
              class="form-control"
              :placeholder="'ui.inputs.enter_description' | translate "
              rows="5"
              v-model="userInfoForm.description")
            span.form-text.invalid {{ 'description' | errorText }}

        .col-md-12
          .tab-pane.kt-portlet__form-buttons.position-right
            v-btn(
              :loading="loading"
              @click="applyChanges") {{ 'ui.buttons.apply' | translate }}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProfileGeneral',
  props: {
    userInfoForm: Object,
    isOwnProfileEditingPermitted: Boolean
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions('app', [
      'updateUserInfo',
      'clearErrors'
    ]),

    applyChanges () {
      this.$emit('apply-changes', this.userInfoForm)
    }

  }
}
</script>

<style lang="scss" scoped>
.form-group > label {
  color: $body-text;
  font-weight: 400;
}
</style>
