<template lang="pug">
  v-card-content
      .row
        .col-md-6
          .form-group
            label {{ 'pages.settings.myskilltree_look' | translate }}
            v-info-icon(:text="'infotext.select_the_visualization' | translate")
            v-select(
              single
              track-by="title"
              :options="treeOptions"
              :disabled="isDesignDisableLookSelection"
              :value="isSkilltreeDefaultLook"
              @input="onTreeChange")
        .col-md-6
          .form-group
            label {{ 'ui.labels.language' | translate }}
            v-select(
              single
              track-by="title"
              :placeholder="'ui.dropdown.select_language' | translate"
              :options="languages"
              v-model="selectedLang")
      .row
        .col-md-6
          .form-group
            label {{ 'pages.settings.myskilltree_default_view' | translate }}
            v-info-icon(:text="'infotext.select_the_default_view' | translate")
            .col-item
              v-select(
                single
                hideBottomMargin
                :label="'ui.labels.my_skill_profile' | translate"
                :options="views"
                :value="isDefaultView"
                @input="setDefaultView")
        .col-md-6
          .form-group
            label {{ 'pages.settings.skilltree_builder_look' | translate }}
            v-info-icon(:text="'infotext.select_the_default_view_builder' | translate")
            .col-item
              v-select(
                single
                track-by="title"
                :options="builderOptions"
                :value="isBuilderDefaultLook"
                @input="onBuilderChange")
        .row
        .col-md-6
          .horizontal-group
            label {{ 'pages.auth.password' | translate }}
            div.mx-4
              v-btn(
                outlinedText
                @click="toggleChangePasswordModal(true)")
                | {{ 'pages.auth.change_password' | translate }}
      change-password-modal(
        :show="showChangePasswordModal"
        @on-close="toggleChangePasswordModal(false)")
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import { ENV, PREFERENCES_VIEWS, TREE_OPTIONS, BUILDER_OPTIONS } from '@/util/constants'
import { mapGetters, mapActions } from 'vuex'
import ChangePasswordModal from '../modals/ChangePasswordModal'

export default {
  name: 'ProfileSettings',

  components: {
    ChangePasswordModal
  },

  props: {
    loading: Boolean,
    userInfoForm: Object,
    isOwnProfileEditingPermitted: Boolean
  },

  data: () => ({
    treeView: null,
    selectedLang: null,
    showChangePasswordModal: false
  }),

  async mounted () {
    this.treeView = this.useNewTree
      ? this.treeOptions[1]
      : this.treeOptions[0]
    this.selectedLang = this.userInfoForm.language
    this.loadLanguages()
    await this.getDefaultView()
    await this.getSkilltreeDefaultLook()
    await this.getBuilderDefaultLook()
  },

  methods: {
    ...mapActions('app', [
      'loadLanguages'
    ]),

    ...mapActions('trees', [
      'setUseNewTree'
    ]),

    ...mapActions('preferences', [
      'getDefaultView',
      'setDefaultView',
      'getSkilltreeDefaultLook',
      'getBuilderDefaultLook',
      'setDefaultSkilltreeLook',
      'setBuilderDefaultLook'
    ]),

    async setLanguage (langId) {
      if (langId) {
        this.loading = true
        const { data, error } = await api.loadTranslate(langId)
        this.userInfoForm.language = this.selectedLang
        this.$emit('apply-changes', this.userInfoForm)
        if (!error) {
          Vue.i18n.add(langId, data)
          if (ENV.SHOW_DEBUG_TOOLS && this.debugOptions.translationKeyMode) return true
          Vue.i18n.set(langId)
        }
        this.loading = false
      }
    },

    toggleChangePasswordModal (param) {
      this.showChangePasswordModal = param
    },

    onTreeChange (value) {
      if (value) {
        this.setUseNewTree(value.use)
        this.setDefaultSkilltreeLook(value)
      }
    },

    onBuilderChange (value) {
      if (value) {
        this.setBuilderDefaultLook(value)
      }
    }
  },

  computed: {
    ...mapGetters('app', [
      'languages',
      'isDesignDisableLookSelection'
    ]),

    ...mapGetters('debug', [
      'debugOptions'
    ]),

    ...mapGetters('preferences', [
      'isDefaultView',
      'isSkilltreeDefaultLook',
      'isBuilderDefaultLook'
    ]),

    ...mapGetters('trees', [
      'useNewTree'
    ]),

    views () {
      return PREFERENCES_VIEWS
    },

    treeOptions () {
      return TREE_OPTIONS
    },

    builderOptions () {
      return BUILDER_OPTIONS
    }
  },

  watch: {
    selectedLang (newValue, oldValue) {
      if (oldValue !== null) {
        this.setLanguage(newValue?.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group, .horizontal-group {
  & > label {
    color: $body-text;
    font-weight: 400;
  }
}

.horizontal-group {
  display: flex;
  align-items: center;
}
</style>
<style>

.column-and-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
